<template>
  <div class="contain">
    <div class="header-title">
      <el-page-header
        @back="onCancel"
        :content="pageTitle"
      >
        <template slot="title">
          <i class="el-icon-arrow-left"></i><span>返回</span>
        </template>
      </el-page-header>
    </div>
    <div class="form">
      <div class="view-body">
        <div class="view-body-title">{{ taskInfo.templateName }}</div>
        <div class="view-body-remark">{{ taskInfo.description }}</div>
        <div class="dashed-line"></div>
        <div class="view-body-content">
          <div class="content-title">一、园校自评</div>
          <div
            class="content-info"
            :class="{ disabledText: jumpType != 'school' }"
          >
            <div
              class="info-item"
              v-for="(item,i) in schoolEvaluateList"
              :key="item.id"
            >
              <div class="item-title">{{ i+1 }}.{{ item.optionsName }}</div>
              <div class="item-text">
                <el-input
                  type="textarea"
                  :rows="6"
                  v-model="item.optionsText"
                  :placeholder="jumpType != 'school' ? '' : '请输入'+item.optionsName+'评价内容'"
                  :disabled="jumpType != 'school'"
                  resize="none"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="view-body-content">
          <div class="content-title">二、分公司评价</div>
          <div
            class="content-info"
            :class="{ disabledText: jumpType != 'company' }"
          >
            <div
              class="info-item"
              v-for="(item,i) in companyEvaluateList"
              :key="item.id"
            >
              <div class="item-title">{{ i+1 }}.{{ item.optionsName }}</div>
              <div class="item-text">
                <el-input
                  type="textarea"
                  :rows="6"
                  v-model="item.optionsText"
                  :placeholder="jumpType != 'company' ? '' : '请输入'+item.optionsName+'评价内容'"
                  :disabled="jumpType != 'company'"
                  resize="none"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="view-body-content">
          <div class="content-title">三、总部评价</div>
          <div
            class="content-info"
            :class="{ disabledText: jumpType != 'headquarters' }"
          >
            <div
              class="info-item"
              v-for="(item,i) in headerEvaluateList"
              :key="item.id"
            >
              <div class="item-title">{{ i+1 }}.{{ item.optionsName }}</div>
              <div class="item-text">
                <el-input
                  type="textarea"
                  :rows="6"
                  v-model="item.optionsText"
                  :placeholder="jumpType != 'headquarters' ? '' : '请输入'+item.optionsName+'评价内容'"
                  :disabled="jumpType != 'headquarters'"
                  resize="none"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="view-body-content">
          <div class="content-title">四、需要协调或重点关注事项</div>
          <div
            class="content-info textarea"
            :class="{ disabledText: jumpType != 'headquarters' }"
          >
            <el-input
              type="textarea"
              :rows="6"
              :placeholder="jumpType != 'headquarters' ? '' : '请输入内容'"
              v-model="coordinateOptions"
              :disabled="jumpType != 'headquarters'"
              resize="none"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="form-btn">
      <el-button
        class="cancel"
        :loading="buttonLoading"
        @click="onCancel"
      >取消</el-button>
      <el-button
        class="submit"
        :loading="buttonLoading"
        @click="onSave"
      >保存</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'totalScoreDetail',
  data () {
    return {
      pageTitle: '查看总评',//页面标题
      jumpType: '',//跳转方式
      taskInfo: {
        taskName: '',
        remark: ''
      },//任务信息
      schoolEvaluateList: [],//园校自评list
      companyEvaluateList: [],//分公司评价list
      headerEvaluateList: [],//总部评价list
      coordinateOptions: '', //需要协调事项

      buttonLoading: false, //按钮loading
    }
  },
  created () {
    this.jumpType = this.$route.query.jumpType
    this.id = this.$route.query.id
    this.getEvaluateInfo()
  },
  methods: {
    //获取总评信息
    getEvaluateInfo () {
      this.$api.getAgencyTaskAllEvaluateInfo(this.id).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.data)
          let resultInfo = res.data.data[0]
          let schoolList = resultInfo.parkComment ? resultInfo.parkComment.split("&&&") : []
          let schoolResultList = []
          let companyList = resultInfo.branchComment ? resultInfo.branchComment.split("&&&") : []
          let companyResultList = []
          let headerList = resultInfo.groupComment ? resultInfo.groupComment.split("&&&") : []
          let headerResultList = []
          if (schoolList.length > 0) {
            schoolList.map((item) => {
              let itemList = item.split("###")
              let info = {
                optionsName: itemList.length > 0 ? itemList[0] : '',
                optionsText: itemList.length == 2 ? itemList[1] : ''
              }
              schoolResultList.push(info)
            })
          }
          if (companyList.length > 0) {
            companyList.map((item) => {
              let itemList = item.split("###")
              let info = {
                optionsName: itemList.length > 0 ? itemList[0] : '',
                optionsText: itemList.length == 2 ? itemList[1] : ''
              }
              companyResultList.push(info)
            })
          }
          if (headerList.length > 0) {
            headerList.map((item) => {
              let itemList = item.split("###")
              let info = {
                optionsName: itemList.length > 0 ? itemList[0] : '',
                optionsText: itemList.length == 2 ? itemList[1] : ''
              }
              headerResultList.push(info)
            })
          }
          console.log(schoolList)
          console.log(schoolResultList)
          this.schoolEvaluateList = schoolResultList
          this.companyEvaluateList = companyResultList
          this.headerEvaluateList = headerResultList
          this.coordinateOptions = resultInfo.coordination
          this.taskInfo = resultInfo
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => { })
    },
    //取消
    onCancel () {
      this.$router.back(-1)
    },
    //保存
    onSave () {
      let parkComment = []
      let branchComment = []
      let groupComment = []
      if (this.schoolEvaluateList.length > 0) {
        this.schoolEvaluateList.map((item) => {
          let list = [item.optionsName, item.optionsText]
          parkComment.push(list.join("###"))
        })
      }
      if (this.companyEvaluateList.length > 0) {
        this.companyEvaluateList.map((item) => {
          let list = [item.optionsName, item.optionsText]
          branchComment.push(list.join("###"))
        })
      }
      if (this.headerEvaluateList.length > 0) {
        this.headerEvaluateList.map((item) => {
          let list = [item.optionsName, item.optionsText]
          groupComment.push(list.join("###"))
        })
      }
      let params = {
        id: this.taskInfo.id,
        orderId: this.taskInfo.orderId,
        listId: this.taskInfo.listId,
        templateId: this.taskInfo.templateId,
        templateName: this.taskInfo.templateName,
        supervisionTopic: this.taskInfo.supervisionTopic,
        description: this.taskInfo.description,
        parkComment: parkComment.join("&&&"),
        branchComment: branchComment.join("&&&"),
        groupComment: groupComment.join("&&&"),
        coordination: this.coordinateOptions
      }
      this.buttonLoading = true
      this.$api.updateAgencyTaskAllEvaluate(params).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success", message: "保存成功!", duration: 2000, onClose: () => {
              this.buttonLoading = false
              this.onCancel()
            },
          });
        } else {
          this.buttonLoading = false
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.buttonLoading = false
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.contain {
  width: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .header-title {
    width: 100%;
    color: #3d3e4f;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .return-button {
      &.el-button:focus {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
      &.el-button:hover {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
    }
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/.el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }
  .dashed-line {
    width: 100%;
    height: 0;
    border-bottom: 1px dashed #e2e7f0;
    margin: 40px 0;
  }
  .form {
    width: calc(100% - 80px);
    padding: 0 40px;
    min-height: 696px;
    .elInput {
      width: 100%;
    }
    .form-title {
      width: 100%;
      color: #3d3e4f;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 40px;
    }
    /deep/.el-form-item .el-select {
      width: 100%;
      height: 40px;
    }

    /deep/.el-form-item__label {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    /deep/.el-input--suffix .el-input__inner {
      padding-right: 30px;
    }
    /deep/.el-input--suffix .el-input__inner,
    /deep/.el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/.el-textarea__inner {
      height: 80px;
    }
    /deep/.el-switch__label.is-active {
      color: #010334;
    }
    /deep/.el-switch__label {
      color: #010334;
    }
    /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
      color: #003685;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #003685 !important;
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__inner:hover {
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__label {
      color: #010334 !important;
    }
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 130px;

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    .return {
      display: block;
      width: 137px;
      height: 40px;
      background: #c6000b;
      box-shadow: 0px 3px 10px rgba(198, 0, 11, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    .save {
      display: block;
      width: 137px;
      height: 40px;
      background: #e7c69c;
      box-shadow: 0px 3px 10px rgba(231, 198, 156, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
  /deep/.el-table {
    // margin-top: 14px;
  }
  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }
  .table-handle.file {
    display: block;
  }
}
</style>